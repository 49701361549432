
import * as toastr from 'toastr/toastr';
import Utils from "../utils";
import CustomerLocationList from "./customer_location/overview/list";

export default class DefaultController {
    entity = "";
    id = null
    data: any = {}
    datatableData =  []
    childs = {}
    protected toastr: any;

    constructor() {
        toastr.options = {
            "maxOpened": 1,
            "autoDismiss": true,
            "closeButton": false,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "rtl": false,
            "showDuration": 300,
            "hideDuration": 1000,
            "timeOut": 5000,
            "extendedTimeOut": 1000,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        this.toastr = toastr
        if ((document.querySelector("div.row[data-entity-id]") as HTMLElement)) {
            // @ts-ignore
            this.id = (document.querySelector("div.row[data-entity-id]") as HTMLElement).getAttribute("data-entity-id");
        }
        this.init();
    }
    async init() {
        this.bindListeners();
        if (this.id) {
            await this.getEntity();
        } else {
            await Utils.createSelects(this.data, (elem: any) => { return this.onSelectGetFilterOptions(elem)}, (data: any, entity: any, element: any) => { return this.onSelectFilterResults(data, entity, element)}, (data: any, entity: any) => {return this.onCreateNewElement(data, entity)});
        }
        await Utils.hideLoader();
    }

    protected getEntityData(elem: any) {
        console.error(`getEntityData is not defined!`)
    }

    protected bindListeners() {
    }

    protected async getEntity() {
        const r = await Utils.entity.get(this.id, this.entity);
        if (r.status === 200) {
            this.data = r.data[0]
            await Utils.updateElements(this.data);
            await Utils.createSelects(this.data, (elem: any) => { return this.onSelectGetFilterOptions(elem)}, (data: any, entity: any, element: any) => { return this.onSelectFilterResults(data, entity, element)}, (data: any, entity: any) => {return this.onCreateNewElement(data, entity)});
            await this.updateEntity(this.data);
            if (this.childs) {
                Object.keys(this.childs).forEach((childKey: any) => {
                    // @ts-ignore
                    const child = this.childs[childKey];
                    if (child && child.update) {
                        child.update(this.data);
                    }
                })
            }
        }
    }

    onSelectFilterResults(data: any, entity: any, element: any) {
        let r = data
        if (this.childs) {
            Object.keys(this.childs).forEach((childName: string) => {
                // @ts-ignore
                const child = this.childs[childName]
                if (child.onSelectFilterResults) {
                   r = child.onSelectFilterResults(r, entity, element)
                }
            });
        }
        return r;
    }

    onCreateNewElement(data: any, entity: any) {
        let r = {}
        if (this.childs) {
            Object.keys(this.childs).forEach((childName: string) => {
                // @ts-ignore
                const child = this.childs[childName]
                if (child.onCreateNewElement) {
                    r = {...r, ...child.onCreateNewElement(data, entity)}
                }
            });
        }
        return r;
    }

    onSelectGetFilterOptions(elem: any) {
        let r = {}
        if (this.childs) {
            Object.keys(this.childs).forEach((childName: string) => {
                // @ts-ignore
                const child = this.childs[childName]
                if (child.onSelectGetFilterOptions) {
                    r = {...r, ...child.onSelectGetFilterOptions(elem)}
                }
            });
        }
        return r;
    }
    async updateEntity(data: any) {
        
    }
}